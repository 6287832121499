import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { Carousel } from '../../common/carousel';
import { Content } from '../../common/content';
import { pictureFluidType } from '../../../types/picture';
import { FluidPicture } from '../../common/picture';
import './gallery.scss';

const bem = new BEMHelper({
  name: 'content-gallery',
});

// The gatsby-image applies a lot of inline styles that we don't need here. We
// use this const to define and reset all the un-required css properties:
const STYLE_RESET = {
  position: '',
  top: '',
  left: '',
  width: '',
  height: '',
  objectFit: '',
  overflow: '',
};

export const Gallery = ({ id, gallery: [gallery] }) => {
  const { items, warning } = gallery;
  const { t } = useTranslation();

  return (
    <Content id={id} {...bem()}>
      <Carousel {...bem('slider')} autoResize>
        {warning && (
          <div {...bem('slide')}>
            <div {...bem('slide__viewport')}>
              <div {...bem('slide__warning')}>
                <p {...bem('slide__warning__text')}>
                  <strong {...bem('slide__warning__headline')}>
                    {t('Warning')}
                  </strong>
                  {warning}
                </p>
              </div>
            </div>
            <p {...bem('slide__caption')}>
              {t('Please click through to continue to the next slide.')}
            </p>
          </div>
        )}
        {items.map(({ image: [image], caption, footnotes }, index) => {
          const key = `${image.url}-${index}`;

          return (
            <figure key={key} {...bem('slide')}>
              <div {...bem('slide__viewport')}>
                <FluidPicture
                  {...bem('slide__background')}
                  role="presentation"
                  aria-hidden="true"
                  style={STYLE_RESET}
                  imgStyle={STYLE_RESET}
                  widths={[238, 476, 952, 1428, 1904]}
                  maxWidth={952}
                  {...image}
                />
                <FluidPicture
                  {...bem('slide__image')}
                  style={STYLE_RESET}
                  imgStyle={STYLE_RESET}
                  widths={[238, 476, 952, 1428, 1904]}
                  maxWidth={952}
                  {...image}
                />
              </div>
              {caption && (
                <figcaption {...bem('slide__caption')}>
                  {parse(caption)}
                  {footnotes && (
                    <footer {...bem('slide__footnotes')}>
                      {parse(footnotes)}
                    </footer>
                  )}
                </figcaption>
              )}
            </figure>
          );
        })}
      </Carousel>
    </Content>
  );
};

Gallery.propTypes = {
  id: string.isRequired,
  gallery: arrayOf(shape({
    warning: string,
    items: arrayOf(shape({
      image: arrayOf(pictureFluidType).isRequired,
      caption: string,
      footnotes: string,
    })).isRequired,
  })).isRequired,
};

export const fragment = graphql`
  fragment GalleryContentFragment on CraftGQL_pageContents_gallery_BlockType {
    id
    gallery {
      ...on CraftGQL_pageContentsGallery_contentsGallery_Entry {
        warning: contentGalleryWarning
        items: contentGalleryItems {
          ...on CraftGQL_contentGalleryItems_image_BlockType {
            image {
              url
              ...Picture
              fluid_0: url(width: 238, mode: "crop", position: "") # 0.25
              fluid_1: url(width: 476, mode: "crop", position: "") # 0.5
              fluid_2: url(width: 952, mode: "crop", position: "") # max width
              fluid_3: url(width: 1428, mode: "crop", position: "") # 1.5
              fluid_4: url(width: 1904, mode: "crop", position: "") # 2
            }
            caption
            footnotes
          }
        }
      }
    }
  }
`;
